import React, { useState, useContext, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _map from 'lodash/map';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { colors, z, bz, font, fontOffset } from '../consts/style';
import { hero } from '../consts/data';
import mq from '../style/mq';
import { fadeIn } from '../style/animations';

import heroContext from '../store/heroContext';
import useBrowser from '../hooks/useBrowser';

import DividerSrc from '../images/divider-orange.svg';

import { ScrollTopNow } from '../utils/helpers';
import DownArrow from './Icons/DownArrow';

const Wrapper = styled.div`
  transition: all 1s;
  ${bz};
  position: fixed;
  top: ${(props) => (props.fading ? '-100%' : '0')};
  left: 0;
  height: 100%;
  width: 100%;
  background: ${colors.blue};
  z-index: ${z.hero};
  overflow: hidden;
  &::after {
    content: '';
    background: rgba(0, 28, 53, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

const Down = styled.button`
  svg {
    width: 5rem;
  }
  transition: bottom 1s;
  ${bz};
  bottom: ${(props) => (props.triggered ? '-400px' : '0')};
  position: absolute;
  right: calc(50% - 2.5rem);
  z-index: 10;
  border: none;
  background: transparent;
  padding: 0;
`;

const BottomBorder = styled.div`
  background: ${colors.blue};
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1.5rem;
  width: 100%;
  z-index: 5;
  ${mq.tabletSmall`
    display: none;
  `}
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 9.5rem;
  padding-bottom: 15rem;
  ${mq.tablet`
  padding-left: 10rem;
  padding-bottom: 24rem;
  `}
  ${mq.tabletSmall`
  padding-left: 6rem;
  padding-right: 6rem;
  padding-bottom: 20rem;
  `}
  ${mq.mobile`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 35%;
  `}
  .text {
    position: relative;
    transition: all 0.5s;
    opacity: ${(props) => (props.trans ? 0 : 1)};
    top: ${(props) => (props.trans ? '2rem' : 0)};
    ${bz};
    width: 43rem;
    max-width: 100%;
    h1 {
      ${font.h1};
      margin: 0;
    }
    h3 {
      ${font.pDisplay};
      margin: 0;
    }
  }
  .controls {
    position: relative;
    transition: all 0.5s;
    opacity: ${(props) => (props.trans ? 0 : 1)};
    top: ${(props) => (props.trans ? '2rem' : 0)};
    ${bz};
    margin-top: 1.2rem;
    display: flex;
    align-items: center;
    button {
      margin-left: 2rem;
      background: transparent;
      border: none;
      padding: 0;
      &.circles {
        border: 0.1rem solid white;
        border-radius: 50%;
        height: 1.2rem;
        width: 1.2rem;
        transition: 0.5s border-color;
        &:hover {
          border-color: ${colors.orange};
        }
      }
      &.nums {
        ${font.p};
        font-weight: bold;
        color: white;
        ${fontOffset};
        top: 0.33rem;
        pointer-events: none;
      }
    }
  }
`;

const MobileImage = styled(Img)`
  ${bz};
  transition: all 1s;
  position: absolute;
  top: ${(props) => (props.shrunk ? '4rem' : '0rem')};
  left: ${(props) => (props.shrunk ? '4rem' : '0rem')};
  width: ${(props) => (props.shrunk ? 'calc(100% - 8rem)' : '100%')};
  height: ${(props) => (props.shrunk ? 'calc(100% - 8rem)' : '100%')};
  transform: translateY(${(props) => props.translate}%);
  position: absolute !important;
  z-index: 0;
  animation: ${fadeIn} 1s;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(0, 28, 53, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

const VideoButton = styled.button`
  ${font.button};
  position: fixed;
  bottom: 4.8rem;
  right: 4.8rem;
  transition: all 0.5s;
  opacity: 1;
  ${bz};
  &.triggered {
    bottom: 1rem;
    opacity: 0;
  }
  ${mq.mobile`
  width: unset;
  right: calc(50% - 9.8rem);
  bottom: calc(50% + 2.2rem);
  &.triggered {
    bottom: calc(50% - 2.2rem);
  }
  `}
`;

const imgQuery = graphql`
  {
    golf: file(relativePath: { eq: "golf.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hole: file(relativePath: { eq: "hole.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tennis: file(relativePath: { eq: "tennis.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

// Touch Variables
let initialX = null;
let initialY = null;

export default function VideoHero({ triggerModalVideo }) {
  const [inMotion, setInMotion] = useState(false);
  const [isDesktop, setDesktop] = useState(false);
  const [isModernBrowser, setIsModernBrowser] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [triggered, setTriggered] = useState(false);
  const { hide, fade, fading } = useContext(heroContext);
  const imageData = useStaticQuery(imgQuery);
  const browser = useBrowser();
  const showVideo = isDesktop && isModernBrowser;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(browser && !browser.mobile);
      setIsModernBrowser(browser && !['edge', 'ie'].includes(browser.name));
      subscribeToListeners();
    }
    return () => {
      unsubscribeToListeners();
    };
  }, []);

  function subscribeToListeners() {
    setTouchListeners();
    setWheelListener();
  }

  function unsubscribeToListeners() {
    removeTouchListeners();
    removeWheelListener();
  }

  //*********** Event Listener Methods

  function setTouchListeners() {
    if (typeof window !== 'undefined') {
      window.addEventListener('touchstart', handleTouchStart, false);
      window.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    }
  }

  function removeTouchListeners() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('touchstart', handleTouchStart, false);
      window.removeEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    }
  }

  function setWheelListener() {
    if (typeof window !== 'undefined') {
      window.addEventListener('wheel', handleWheel, {
        passive: false,
      });
    }
  }

  function removeWheelListener() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('wheel', handleWheel, {
        passive: false,
      });
    }
  }

  //*********** Helper Methods

  function handleTouchStart(e) {
    initialX = e.touches[0].clientX;
    initialY = e.touches[0].clientY;
  }

  function handleTouchMove(e) {
    if (initialX === null) {
      return;
    }

    if (initialY === null) {
      return;
    }

    let currentX = e.touches[0].clientX;
    let currentY = e.touches[0].clientY;

    let diffX = initialX - currentX;
    let diffY = initialY - currentY;

    if (Math.abs(diffX) < Math.abs(diffY)) {
      diffY > 0 ? handleWheelDown() : handleWheelUp();
    }

    initialX = null;
    initialY = null;

    e.preventDefault();
  }

  function handleWheel(e) {
    e.preventDefault();
    let direction = e.deltaY < 0 ? 'up' : 'down';
    direction === 'up' ? handleWheelUp() : handleWheelDown();
  }

  function handleWheelUp() {
    return null;
  }

  function handleWheelDown() {
    handleRemove();
  }

  function handleChange(i) {
    setInMotion(true);
    const changeIndex = setTimeout(() => {
      setSlideIndex(i);
    }, 1000);
    const resetMotion = setTimeout(() => {
      setInMotion(false);
    }, 2000);
    return () => clearTimeout([changeIndex, resetMotion]);
  }

  function handleRemove() {
    ScrollTopNow();
    const doTrigger = setTimeout(() => {
      setTriggered(true);
      fade();
    }, 0);
    const doHide = setTimeout(() => {
      hide();
    }, 1000);
    return () => clearTimeout([doTrigger, doHide]);
  }

  function getTranslateValue(idx, currentIndex) {
    if (idx === currentIndex) return 0;
    return (idx - currentIndex) * 105;
  }

  return (
    <Wrapper fading={fading}>
      {showVideo &&
        _map(hero, (v, i) => (
          <Video
            data={v}
            shrunk={inMotion}
            key={i}
            videoIndex={i}
            translate={getTranslateValue(i, slideIndex)}
          />
        ))}
      {!showVideo &&
        _map(hero, (v, i) => (
          <MobileImage
            backgroundColor={colors.blue}
            key={i}
            shrunk={inMotion}
            fluid={imageData[v.image].childImageSharp.fluid}
            translate={getTranslateValue(i, slideIndex)}
          />
        ))}
      <Content trans={inMotion || triggered}>
        <VideoButton
          className={(inMotion || triggered || fading) && 'triggered'}
          onClick={triggerModalVideo}
        >
          <span>Play Video</span>
        </VideoButton>
        <div className="text">
          <h1>{hero[slideIndex].title}</h1>
          <h3>{hero[slideIndex].body}</h3>
        </div>
        <div className="controls">
          <img src={DividerSrc} alt="Divider" />
          {_map(hero, (_noop, i) => {
            return (
              <button
                key={i}
                onClick={() => handleChange(i)}
                className={i === slideIndex ? 'nums' : 'circles'}
              >
                {i === slideIndex && `0${i + 1}`}
              </button>
            );
          })}
        </div>
      </Content>
      <Down triggered={triggered || fading} onClick={handleRemove}>
        <DownArrow />
      </Down>
      <BottomBorder />
    </Wrapper>
  );
}

VideoHero.propTypes = {
  triggerModalVideo: PropTypes.func.isRequired,
};

const VideoWrapper = styled.video`
  ${bz};
  transition: all 1s;
  position: absolute;
  top: ${(props) => (props.shrunk ? '4rem' : '0rem')};
  left: ${(props) => (props.shrunk ? '4rem' : '0rem')};
  width: ${(props) => (props.shrunk ? 'calc(100% - 8rem)' : '100%')};
  height: ${(props) => (props.shrunk ? 'calc(100% - 8rem)' : '100%')};
  transform: translateY(${(props) => props.translate}%);
  object-fit: cover;
  overflow: hidden;
  animation: ${fadeIn} 1s;
  &::after {
    content: '';
    background: rgba(0, 28, 53, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 4000;
  }
`;

const Video = ({ data, shrunk, translate }) => {
  const { poster, mp4, webm } = data;
  return (
    <Fragment>
      <VideoWrapper
        className="video-bg"
        poster={poster}
        shrunk={shrunk}
        translate={translate}
        loop
        muted
        autoPlay
      >
        <source src={mp4} type="video/mp4" />
        <source src={webm} type="video/webm" />
      </VideoWrapper>
    </Fragment>
  );
};

Video.propTypes = {
  data: PropTypes.object.isRequired,
  shrunk: PropTypes.bool.isRequired,
  translate: PropTypes.number.isRequired,
};
