/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import browser from 'browser-detect';
import mq from '../style/mq';
import Fade from './Fade';
import { font, colors, fontOffset } from '../consts/style';

const Wrapper = styled.div`
  margin-bottom: 14.4rem;
  ${mq.mobile`
  margin-bottom: 9rem;
  `}
`;

const Inner = styled.div`
  width: 83.33%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 2rem;
  ${mq.tabletSmall`
  width: 100%;
  `}
  ${mq.mobile`
  width: calc(100% - 3.2rem);
  `}
  .content {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    padding: 6rem 0 10rem;
    ${mq.mobile`
    padding: 4rem 0 6rem;
    `}
    h2 {
      ${font.h2};
      font-weight: normal;
      margin-bottom: 3.2rem;
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }

    button {
      ${font.button};
      margin: 0 auto;
      background: transparent;
      border: 0.2rem solid ${colors.orange};
      color: ${colors.orange};
      font-weight: bold;
      span {
        ${fontOffset};
      }
    }

    ${mq.mobile`
      button {
       padding-left: 0;
       padding-right: 0;
       font-size: 1.6rem;
      }
    `}
  }
`;

const Bg = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  background: ${colors.grey};
`;

export default class VideoModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      isDesktop: false,
      isModernBrowser: false,
    };
  }
  static propTypes = {
    title: PropTypes.string.isRequired,
    mp4: PropTypes.string.isRequired,
    webm: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    brochure: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const b = browser();
      this.setState({
        isDesktop: b && !b.mobile,
        isModernBrowser: b && !['edge', 'ie'].includes(b.name),
      });
    }
  }

  togglePlay() {
    const { playing } = this.state;
    if (playing) {
      this.refs.videoRef.pause();
    } else this.refs.videoRef.play();
    this.setState({ playing: !playing });
  }

  render() {
    const { title, cta, brochure } = this.props;
    return (
      <Wrapper>
        <Fade>
          <Inner>
            <div className="content">
              <h2>{title}</h2>
              <a target="_blank" rel="noopener noreferrer" href={brochure}>
                <button>
                  <span>{cta}</span>
                </button>
              </a>
            </div>

            <Bg />
          </Inner>
        </Fade>
      </Wrapper>
    );
  }
}
