import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import mq from '../style/mq';
import Fade from './Fade';
import { font } from '../consts/style';
import { fadeInUp } from '../style/animations';

import GreyArrow from './Icons/GreyArrow';

const Wrapper = styled.div`
  margin-bottom: 14.4rem;
  ${mq.mobile`
  margin-bottom: 9rem;
  `}
`;

const Inner = styled.div`
  width: 66.66%;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  ${mq.tablet`
    width: 58rem;
  `}
`;

const ContentWrapper = styled.div`
  position: relative;
  .title {
    ${font.h2};
  }
  .slides {
    height: 20rem;
    ${mq.mobile`
    height: 25rem;
    `}
  }
`;
const Slide = styled.div`
  margin: 0 auto;
  position: relative;
  animation: ${fadeInUp} 0.5s;
  h3 {
    ${font.pCaps};
    margin: 1.2rem 0;
  }
  p {
    ${font.p};
  }
  width: 40.8rem;
  max-width: 100%;
  ${mq.tablet`
  width: 38rem;
`}
  ${mq.mobile`
  padding: 0 1rem;
  `}
`;
const Control = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  padding: 0;
  top: 8rem;
  ${mq.mobile`
    top: 4rem;
  svg {

    width: 3.4rem;
  }
  `}
`;
const Right = styled(Control)`
  right: 0;
  ${mq.mobile`
  right: 1.6rem;
  `}
`;
const Left = styled(Control)`
  left: 0;
  ${mq.mobile`
  left: 1.6rem;
`}
`;

export default function PageSliderRight({ data, title }) {
  const [pageIndex, setPageIndex] = useState(0);

  function renderSlides() {
    return _map(data, (s, i) => {
      return pageIndex === i ? (
        <Slide key={i}>
          <h3>{s.title}</h3>
          <p>{s.body}</p>
        </Slide>
      ) : null;
    });
  }

  function handleClick(direction) {
    if (direction === 'next') {
      if (pageIndex === data.length - 1) return setPageIndex(0);
      else return setPageIndex(pageIndex + 1);
    }
    if (pageIndex === 0) return setPageIndex(data.length - 1);
    else return setPageIndex(pageIndex - 1);
  }

  return (
    <Fade>
      <Wrapper>
        <Inner>
          <ContentWrapper>
            <div className="title">{title}</div>
            <div className="slides">{renderSlides()}</div>
            <Right onClick={() => handleClick('next')}>
              <GreyArrow />
            </Right>
            <Left onClick={() => handleClick('prev')}>
              <GreyArrow flip />
            </Left>
          </ContentWrapper>
        </Inner>
      </Wrapper>
    </Fade>
  );
}

PageSliderRight.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};
