import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import Img from 'gatsby-image';

import mq from '../style/mq';
import Fade from './Fade';
import { bz, font, colors, sliderHeight, headingOffset } from '../consts/style';
import SliderControls from './SliderControls';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  margin-bottom: 14.4rem;
  ${mq.mobile`
  margin-bottom: 14rem;
  `}
`;

const Carousel = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  ${sliderHeight};
  ${mq.mobile`
  overflow: visible;
  margin-top: 4rem;
  `}
`;

const ImageWrapper = styled.div`
  position: absolute;
  height: 51rem;
  top: 0;
  width: 83.33%;
  left: 0;
  ${sliderHeight};
  ${mq.mobile`
  width: calc(100% - 1.6rem) !important;
  `}
`;

const Image = styled(Img)`
  transition: width 1s;
  position: absolute !important;
  top: 0;
  right: ${(props) => props.active && '0'};
  left: ${(props) => !props.active && '0'};
  width: ${(props) => (props.active ? '100%' : '0%')};
  ${bz};
  z-index: 0;
  height: 51rem;
  ${sliderHeight};
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

const ControlWrapper = styled.div`
  max-width: 100%;
  padding-top: 2.4rem;
  padding-bottom: 3.2rem;
  ${mq.tabletSmall_up`
    margin-left: calc(16.66% + 2rem);
  `}
  ${mq.tabletSmall`
    margin-left: 16.66%;
  `}
  ${mq.mobile`
    margin-left: 0;
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  ${mq.tabletSmall_up`
    margin-left: calc(16.66% + 2rem);
  `}
  ${mq.tabletSmall`
    margin-left: 16.66%;
  `}
  ${mq.mobile`
    margin-left: 0;
  `}
  .title {
    ${headingOffset};
    ${font.h2};
    width: 30rem;
    flex-shrink: 0;
    ${mq.tablet`
        width: 26rem;
    `}
    ${mq.tabletSmall`
      width: 21rem;
    `}
  }
  .body {
    padding-left: 2rem;
    ${mq.mobile`
    padding-left: 0;
  `}
    a {
      &:hover {
        text-decoration: none;
      }
    }
    p {
      ${font.p};
      margin-bottom: 4rem;
    }
    button {
      ${font.button};
    }
  }
  ${mq.mobile`
    flex-wrap: wrap;
    padding-left: 0;
    .title, .body {
      width: 100%;
    }
    .title {
      margin-bottom: 1.2rem;
    }
  `}
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding: 0 1.6rem;
`;
const InfoInner = styled.div`
  width: 83.33%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  ${mq.tabletSmall`
   width: 100%;
  `}
`;

const Label = styled.div`
  ${font.pCaps};
  position: absolute;
  left: 82%;
  bottom: 5.4rem;
  transform: rotate(90deg);
  ${mq.mobile`
  transform: rotate(0deg);
  top: -4rem;
  right: 1.6rem;
  bottom: unset;
  left: unset;
  `}
`;

export default function HomeSliderLeft({
  sliderData,
  title,
  body,
  ctaText,
  ctaTo,
  label,
}) {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Fade>
      <Wrapper>
        <Carousel>
          <ImageWrapper>
            {_map(sliderData, (slide, imageIndex) => (
              <Image
                active={slideIndex === imageIndex}
                key={imageIndex}
                fluid={slide.image}
                backgroundColor={colors.blue}
              />
            ))}
          </ImageWrapper>
          <Label>{label}</Label>
        </Carousel>
        <Fade>
          <InfoWrapper>
            <InfoInner>
              <ControlWrapper className="row">
                <SliderControls
                  slideIndex={slideIndex}
                  setIndex={setSlideIndex}
                  length={sliderData.length}
                />
              </ControlWrapper>
              <ContentWrapper className="row">
                <div className="title">{title}</div>
                <div className="body">
                  <p>{body}</p>
                  <Link to={ctaTo}>
                    <button>
                      <span>{ctaText}</span>
                    </button>
                  </Link>
                </div>
              </ContentWrapper>
            </InfoInner>
          </InfoWrapper>
        </Fade>
      </Wrapper>
    </Fade>
  );
}

HomeSliderLeft.propTypes = {
  sliderData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaTo: PropTypes.string.isRequired,
};
