import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CancelGreySrc from '../images/cancel-grey.svg';
import { z } from '../consts/style';
import { videoModal } from '../consts/data';
import mq from '../style/mq';

const Wrapper = styled.div`
  background: white;
  position: fixed;
  z-index: ${z.videoModal};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Inner = styled.div`
  width: 75%;
  ${mq.mobile`
  width: calc(100% - 3.2rem);
  `}
`;
const Cancel = styled.button`
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  border: none;
  background: transparent;
`;

export default function ModalVideo({ onCancel }) {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.modal-plyr')).map(
        (p) => new Plyr(p)
      );
    }
  }, []);
  return (
    <Wrapper>
      <Inner>
        <div
          className="modal-plyr"
          data-plyr-provider="vimeo"
          data-plyr-embed-id={videoModal.id}
        />
      </Inner>
      <Cancel onClick={onCancel}>
        <img src={CancelGreySrc} alt="Exit full screen" />
      </Cancel>
    </Wrapper>
  );
}

ModalVideo.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
