import React, { Fragment, useContext, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import VideoHero from '../components/VideoHero';

import heroContext from '../store/heroContext';
import PageWrapper from '../containers/PageWrapper';
import PageHeading from '../components/PageHeading';
import HomeSliderLeft from '../components/HomeSliderLeft';
import HomeSliderRight from '../components/HomeSliderRight';
import TextSlider from '../components/TextSlider';

import { home } from '../consts/data';
import VideoModule from '../components/VideoModule';
import ModalVideo from '../components/ModalVideo';

const homeQuery = graphql`
  {
    Home_Carousel1_Residence_01: file(
      relativePath: { eq: "Home_Carousel1_Residence_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Home_Carousel1_Residence_02: file(
      relativePath: { eq: "Home_Carousel1_Residence_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Home_Carousel2_Grand_01: file(
      relativePath: { eq: "Home_Carousel2_Grand_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Home_Carousel2_Grand_02: file(
      relativePath: { eq: "Home_Carousel2_Grand_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Home_Carousel2_Grand_03: file(
      relativePath: { eq: "Home_Carousel2_Grand_03.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Home_Carousel2_Grand_04: file(
      relativePath: { eq: "Home_Carousel2_Grand_04.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const IndexPage = () => {
  const { shown, fading } = useContext(heroContext);
  const data = useStaticQuery(homeQuery);
  const [modalShown, setModalShown] = useState(false);

  function renderVideoHero() {
    if (shown)
      return <VideoHero triggerModalVideo={() => setModalShown(true)} />;
    return null;
  }
  function renderVideoModal() {
    if (modalShown) return <ModalVideo onCancel={() => setModalShown(false)} />;
    return null;
  }

  // Slider 1 data
  const slider1data = home.slider1.slides;
  slider1data[0].image = data.Home_Carousel1_Residence_01.childImageSharp.fluid;
  slider1data[1].image = data.Home_Carousel1_Residence_02.childImageSharp.fluid;

  const slider2data = home.slider2.slides;
  slider2data[0].image = data.Home_Carousel2_Grand_01.childImageSharp.fluid;
  slider2data[1].image = data.Home_Carousel2_Grand_02.childImageSharp.fluid;
  slider2data[2].image = data.Home_Carousel2_Grand_03.childImageSharp.fluid;
  slider2data[3].image = data.Home_Carousel2_Grand_04.childImageSharp.fluid;

  return (
    <Fragment>
      {renderVideoHero()}
      {renderVideoModal()}
      <SEO
        title="Home | Welcome To Penrose | Penrose Grand Del Mar"
        description="Designed to inspire, Penrose architecture and interiors boast a modern take on the Neoclassical grandeur of the Grand Del Mar."
      />
      <PageWrapper shiftedDown={!fading}>
        <PageHeading title={home.heading.title} body={home.heading.body} />
        <HomeSliderLeft
          title={home.slider1.title}
          body={home.slider1.body}
          label={home.slider1.label}
          ctaText={home.slider1.cta}
          ctaTo={home.slider1.ctaTo}
          sliderData={slider1data}
        />
        <HomeSliderRight
          title={home.slider2.title}
          body={home.slider2.body}
          label={home.slider2.label}
          ctaText={home.slider2.cta}
          ctaTo={home.slider2.ctaTo}
          sliderData={slider2data}
        />
        <TextSlider
          data={home.textSlider.slides}
          title={home.textSlider.title}
        />
        <VideoModule
          title={home.videoBlock.title}
          cta={home.videoBlock.cta}
          brochure={home.videoBlock.brochure}
        />
      </PageWrapper>
    </Fragment>
  );
};

export default IndexPage;
